@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Roboto:wght@300&family=Ubuntu:wght@500&display=swap);
body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

.main {
  min-height: 70vh;
}

.popup-banner {
  position: fixed;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  width: 300px;
  text-align: center;
}

.back_to_top {
  position: fixed;
  background: #3a524a;
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  cursor: pointer;
}

.footer {
  border-top: 1px solid grey;
  padding-top: 10px;
}

.title_footer {
  font-size: 14px;
  font-weight: 600;
}

.links a {
  text-decoration: none;
  color: #848484;
  font-size: 14px;
  font-weight: normal;
}
.links a:hover {
  color: #4ea252;
  transition: 0.3s;
}

.cseLogo {
  width: 70px;
  height: 70px;
}
.cselogoMobile {
  width: 100px;
  height: 81px;
}

.links {
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  color: #5a6e79 !important;
}

.header_manu {
  background-color: white !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  padding: 0;
}



.dropdown-item:hover {
  color: #4ea252 !important;
  transition: 0.3s;
}
.name{
  margin-left: 10px;
  font-size: 24px;
  font-weight: 900;
}

.navbar-light .navbar-nav .nav-link{
  color:  #5a6e79 !important;
}

.navbar-light .navbar-nav .nav-link:hover, .active {
  color: #4ea252 !important;
  transition: 0.5s;
}

.links {
  transition: color 0.3s ease;
}

.links.active {
  color: red; /* Adjust the desired color for the active link */
}
.topheader {
  border-bottom: 1px dotted grey;
  padding-bottom: 10px;
}
.top {
  color: black;
}
.itemRight {
  justify-content: end;
  text-align: right;
}

.icon {
  padding: 5px;
  border: 1px solid gray;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  color: teal;
}
.leftIcon {
  color: teal;
}

.title_committee{
    font-weight: 700;
    color: brown;
}

.title_designation{
    color: lightslategray;
}

.title_track{
    color: darkgreen;
}
a{
    text-decoration: none;
}
.bg-overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(/static/media/DUET-1.cd026d08.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  height: 450px;
  padding-top: 50px;
}

.Section_top {
  width: 100%;
  height: 70vh;
  background-image: url(/static/media/DUET-1.cd026d08.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  justify-content: center;
  position: relative;
}

.Section_top:before {
  content: "";
  background: rgba(7, 9, 14, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.Section_top .content {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 80px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.Section_top h1 {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 40px;
  font-weight: 600;
  /*text-transform: uppercase;*/
}

.Section_top h1 span {
  color: #4ea252;
}

.Section_top p {
  color: #ebebeb;
  font-weight: 700;
  font-size: 20px;
}

.Section_top .about-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #f82249;
}

.Section_top .about-btn:hover {
  background: #f82249;
  color: #fff;
}

@media screen and (max-width: 720px) {
  .Section_top {
    min-height: 30vh;
  }
  .Section_top h1 {
    font-size: calc(1.525rem + 3.3vw) !important;
    font-size: 20px !important;
  }

  .Section_top p {
    font-size: 14px !important;
  }
}

/* Here about */

.title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #182b49;
  border: none;
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}

.card_text {
  text-align: justify;
  line-height: 30px;
}

.card_shdow {
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  margin: 0px;
  padding: 0px;
}

.animated-text {
  -webkit-animation: changeColor 2s infinite;
          animation: changeColor 2s infinite;
}

@-webkit-keyframes changeColor {
  0% {
    color: red;
  }
  25% {
    color: blue;
  }
  50% {
    color: black;
  }
  75% {
    color: brown;
  }
  100% {
    color: green;
  }
}

@keyframes changeColor {
  0% {
    color: red;
  }
  25% {
    color: blue;
  }
  50% {
    color: black;
  }
  75% {
    color: brown;
  }
  100% {
    color: green;
  }
}

.mar_h1{
  width: 700px;
  font-size: 30px;
  margin: 0px;
}

.right_title{
    text-align: right;
}

.right_content{
   justify-content: right;
}

.bograpy{
    font-size: 13px;
    line-height: 25px;
}

.card_hight{
    height: 440px;
}
.child{
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.submission a {
  text-decoration: none;
  color: #72411f;
  font-size: 16px;
  font-weight: bold;
}
.submission a:hover {
  color: #4ea252;
  transition: 0.3s;
}
.text_format li {
  text-align: justify;

}

td, tr {
  text-align: center;
}

.image_size{
    width: 400px;
    height: 350px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

