.topheader {
  border-bottom: 1px dotted grey;
  padding-bottom: 10px;
}
.top {
  color: black;
}
.itemRight {
  justify-content: end;
  text-align: right;
}

.icon {
  padding: 5px;
  border: 1px solid gray;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  color: teal;
}
.leftIcon {
  color: teal;
}
