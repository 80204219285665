.bg-overlay {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../components/assets/images/DUET-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
  height: 450px;
  padding-top: 50px;
}

.Section_top {
  width: 100%;
  height: 70vh;
  background-image: url("../../components/assets/images/DUET-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  justify-content: center;
  position: relative;
}

.Section_top:before {
  content: "";
  background: rgba(7, 9, 14, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.Section_top .content {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 80px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}

.Section_top h1 {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 40px;
  font-weight: 600;
  /*text-transform: uppercase;*/
}

.Section_top h1 span {
  color: #4ea252;
}

.Section_top p {
  color: #ebebeb;
  font-weight: 700;
  font-size: 20px;
}

.Section_top .about-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #f82249;
}

.Section_top .about-btn:hover {
  background: #f82249;
  color: #fff;
}

@media screen and (max-width: 720px) {
  .Section_top {
    min-height: 30vh;
  }
  .Section_top h1 {
    font-size: calc(1.525rem + 3.3vw) !important;
    font-size: 20px !important;
  }

  .Section_top p {
    font-size: 14px !important;
  }
}

/* Here about */

.title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #182b49;
  border: none;
  border-bottom: 1px solid red;
  padding-bottom: 10px;
}

.card_text {
  text-align: justify;
  line-height: 30px;
}

.card_shdow {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  margin: 0px;
  padding: 0px;
}

.animated-text {
  animation: changeColor 2s infinite;
}

@keyframes changeColor {
  0% {
    color: red;
  }
  25% {
    color: blue;
  }
  50% {
    color: black;
  }
  75% {
    color: brown;
  }
  100% {
    color: green;
  }
}

.mar_h1{
  width: 700px;
  font-size: 30px;
  margin: 0px;
}
