.submission a {
  text-decoration: none;
  color: #72411f;
  font-size: 16px;
  font-weight: bold;
}
.submission a:hover {
  color: #4ea252;
  transition: 0.3s;
}
.text_format li {
  text-align: justify;

}
