.right_title{
    text-align: right;
}

.right_content{
   justify-content: right;
}

.bograpy{
    font-size: 13px;
    line-height: 25px;
}

.card_hight{
    height: 440px;
}