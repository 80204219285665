.back_to_top {
  position: fixed;
  background: #3a524a;
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 2px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  cursor: pointer;
}

.footer {
  border-top: 1px solid grey;
  padding-top: 10px;
}

.title_footer {
  font-size: 14px;
  font-weight: 600;
}

.links a {
  text-decoration: none;
  color: #848484;
  font-size: 14px;
  font-weight: normal;
}
.links a:hover {
  color: #4ea252;
  transition: 0.3s;
}
