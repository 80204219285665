@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Roboto:wght@300&family=Ubuntu:wght@500&display=swap");

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

.main {
  min-height: 70vh;
}

.popup-banner {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  width: 300px;
  text-align: center;
}
