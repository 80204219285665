.cseLogo {
  width: 70px;
  height: 70px;
}
.cselogoMobile {
  width: 100px;
  height: 81px;
}

.links {
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  color: #5a6e79 !important;
}

.header_manu {
  background-color: white !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  padding: 0;
}



.dropdown-item:hover {
  color: #4ea252 !important;
  transition: 0.3s;
}
.name{
  margin-left: 10px;
  font-size: 24px;
  font-weight: 900;
}

.navbar-light .navbar-nav .nav-link{
  color:  #5a6e79 !important;
}

.navbar-light .navbar-nav .nav-link:hover, .active {
  color: #4ea252 !important;
  transition: 0.5s;
}

.links {
  transition: color 0.3s ease;
}

.links.active {
  color: red; /* Adjust the desired color for the active link */
}