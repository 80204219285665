.title_committee{
    font-weight: 700;
    color: brown;
}

.title_designation{
    color: lightslategray;
}

.title_track{
    color: darkgreen;
}